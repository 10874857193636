
import { defineComponent } from 'vue'
import store from '@/store'
import WatFocusTesterCalendar from './WatFocusTesterCalendar.vue'
import WatFocusConectivityTest from './WatFocusConectivityTest.vue'
import WatFocusTesterInfo from './WatFocusTesterInfo.vue'
import WatFocusWelcomePage from './WatFocusWelcomePage.vue'
import ModalBasic from '~/components/ModalBasic'

export default defineComponent({
    name: 'WatFocusTask',
    components: {
        WatFocusTesterCalendar,
        WatFocusConectivityTest,
        ModalBasic,
        WatFocusTesterInfo,
        WatFocusWelcomePage
    },
    props: {
        id: {
            type: String,
            default: null,
        },
        testTitle: {
            type: String,
            default: null
        },
        testCountry: {
            type: String,
            default: 'ES'
        },
        watFocusConfig:{
            type: Object,
            default: () => {}
        }
    },
    computed:{
        pageTitle(){
            if(this.showCalendar) return this.$t('wat_focus_calendar_title')
            if(this.showConnectivity) return this.$t('wat_focus_connectivity_test_title')
            if(this.showTesterInfo) return this.$t('wat_focus_tester_title')
        },
        pageInfo(){
            if(this.showCalendar) return this.$t('wat_focus_calendar_subtitle')
            if(this.showConnectivity) return this.$t('wat_focus_connectivity_test_desc')
            if(this.showTesterInfo) return this.$t('wat_focus_tester_info')
        }
    },
    data: () => {
        return {
            showCalendar: false,
            showConnectivity: false,
            showModal: false,
            showTesterInfo: false,
            showWatFocusWelcome: false,
            provinces:[]
        }
    },
    methods:{
        getProvinces(){
            store.dispatch("getProvinces", { country: this.testCountry }).then((data) => {
                this.provinces = data;
            });
        },
        backToCalendar(){
            this.showTesterInfo = false
            this.showCalendar = true
        },
        confirmCalendarDates(event: any){
            if(!this.isPublic()){
                this.showModal = true
            }
            else{
                this.showCalendar = false
                this.showTesterInfo = true
            }
            this.$emit('save-calendar', event)
        },
        saveTesterInfo(event: any){
            this.showModal = true
            this.$emit('save-tester', event)
        },
        saveConectivityTest(event: any){
            this.$emit('save-connectivity', event)
        },
        welcomePageFinish(){
            this.showWatFocusWelcome = false
            this.showCalendar = true
        },
        advertAccepted(){
            this.showTesterInfo = false
            this.showCalendar = false
            this.showModal = false
            this.showConnectivity = true
        },
        isPublic(){
            return process.env.VUE_APP_ENVIROMENT == "WAT_EXECUTION"
        },
        init(){
            if(this.isPublic()){
                this.showWatFocusWelcome = true
            }
            else{
                this.showCalendar = true
            }
        }
    },
    mounted(){
        this.getProvinces()
        this.init()
    }
})
